
.logo {
	position: absolute;
	display: block;
	right: 137px;
	border: 2px solid $color-ci;
	border-radius: 10px;
	padding: $base-spacing-unit;
	text-align: center;
	background-color: #fff;

	@include media-query(palm) {
		float: none;
		margin: $base-spacing-unit $half-spacing-unit;
		position: inherit;
		padding:0;
	}

	@include media-query(lap) {
		width: 33%;
		bottom: 0;
		right: 16px
	}

}

.obereNaviTyp4 {
	right: 12px;
	top: 30px;

	@include media-query(palm) {
		  right: 20px;
		  top: 1px;
	}
}

.sprachdropdown {
	padding: 5px;
	background: $color-ci;
	border-radius: 10px;
	margin-top: -4px;
	transform: scale(0.85);
	position: relative;

	&:after {
		content: "";
		@extend .svg-arrow-down;
		display: block;
		position: absolute;
		right: 13px;
		top: 20px;
	}

	@include media-query(lap) {
		margin-top: 0;
		margin-right: 0;
	}

	@include media-query(palm) {
		margin-right: -19px;
		margin-top: -7px;
		background: none;
		overflow: visible;

		&:after {
			display: none;
		}
	}

}

.languageselectorForm {

	@include media-query(palm) {
		display: block;
		position: relative;
		width: 54.153px;
  	height: 49.811px;
	}

	&:after {
		content: "";
		display: block;
		@extend .svg-lang-mobile;
		margin-top: -100%;

		@include media-query(lap-and-up) {
			display: none;

		}
	}
}

.languageselectorSelect {
	appearance: none;
	width: 108px;
	height: 40px;
	border: 1px solid #44c8f5;

	@include media-query(palm) {
		opacity: 0;
		width: 50px;
		height: 50px;
	}
}

#wrapcontentTyp4 {
	overflow: visible;

}

#headerTyp4 {
  margin: 0 auto;
  max-width: 980px;
  width: 100%;
  position: inherit;
  margin-top: $base-spacing-unit*2;


  @include media-query(palm) {
  	position: relative;
  	top: 0;
		margin-top: 0;
  }

	@include media-query(lap) {
		position: relative;
		top: 0;
		padding: 0 $base-spacing-unit 0 $base-spacing-unit;
	}

}

.hauptnaviJoker2 {

//	background: url(https://dl.dropboxusercontent.com/s/5ftow88yjct4nzh/logo-erfinderland.png) no-repeat;
	background: url(../images/logo-erfinderland.png) no-repeat;
	width: 175px;
	height: 209px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	z-index: 9000;
	cursor: pointer;

	@include media-query(palm) {
		display: none;
	}

	@include media-query(lap) {
		vertical-align: bottom;
	}
}

.logoJoker1 {
//	background: url(https://dl.dropboxusercontent.com/s/5ftow88yjct4nzh/logo-erfinderland.png) no-repeat;
	background: url(../images/logo-erfinderland.png) no-repeat;

	width: 175px;
	height: 209px;
	display: none;
	margin: 20px auto;
	cursor: pointer;

	@include media-query(palm) {
		display: block;
	}
}

.hauptnaviJoker3 {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5000;
	display: none;

	&.active {
		display: block;
	}

}



#hauptnavi {
	position: inherit;
	float: none;
	display: inline-block;
	margin-left: 0;
	max-width: inherit;
	left: inherit;
	z-index: 7000;

	@include media-query(palm) {
		min-height: inherit;
		position: absolute;
		width: 100%;
	}

	@include media-query(lap) {
		position: relative;
		width: 33%;
		vertical-align: bottom;
	}

	> ul {
		float: none;
		border-bottom: 2px solid $color-ci;
		position: absolute;
		right: 0;
		left: 17%;
		top: 160px;
		text-align: right;

		@include media-query(lap) {
			bottom: 0;
			border-radius: 10px;
			overflow: hidden;
			top: auto;
		}

		@include media-query(palm) {
			height: 33px;
			width: 48px;
			padding-top: 20px;
			overflow: hidden;
			background-position-x: 8px;
			background-position-y: 8px;
			border-radius: 10px;
			margin-left: 8px;
			z-index: 7000;
			left: 0;
			top: auto;

			&.active {
				height: inherit;
				width: 100%;
				left: 0;
				margin-top: -20px;
				margin-left: 0;
				background-image: none;
				border-top-left-radius: 30px;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;

				> li {
					display: block!important;
				}
			}
		}

		&:hover li {
			@include media-query(palm) {
				display: none;
			}
		}

		> li {
			display: none;
			overflow: auto;
			padding: 0 8px 0 8px;
			margin-right: $base-spacing-unit;
			line-height: 2;

			@include media-query(palm) {
				display: none!important;
			}

			@include media-query(lap) {
				display: block;
				background-color: $color-ci;
				line-height: 1.6;
				margin-right: 0;

				> a {
					line-height: 1.6!important;
					text-align: left;
				}

				&.active {
					margin-right: 0!important;
					margin-bottom: 0!important;
				}
			}

			@include media-query(desk) {
				display: inline-block;
				margin-bottom: -18px;
				border-radius: 10px;
				background-color: $color-ci;
				vertical-align: middle;
			}

			&:hover {
				color: $color-white;
				background: $color-ci-2nd;
			}

			&.active {
				clear: left;
				float: none;
				padding: 0 8px 0 8px;
				margin-right: $base-spacing-unit;
				color: $color-white;
				text-align: left;
				font-weight: normal;

				@include media-query(lap-and-up) {
					margin-bottom: -18px;
					background: $color-ci-2nd;
				}

				> a, & > a:hover {
					color: $color-white;
				}
			}

			> a {
				line-height: 2;
				padding: 0!important;
				font-weight: normal;
				color: $color-white;
			}
		}
	}
}

// Add your settings here

$base-font-size:    15px;
$base-line-height:  20px;

$base-font-family:  Arial, Helvetica, sans-serif;
$base-ui-color:     #ccc!default;



/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/

/**
 * Tell inuit.css when breakpoints start.
 */
$lap-start:         768px;
$desk-start:        1025px;
$desk-wide-start:   1440px;



/*------------------------------------*\
    $FONT-SIZES
\*------------------------------------*/
/**
 * Font-sizes (in pixels). Refer to relevant sections for their implementations.
 */
$giga-size:         96px!default;
$mega-size:         72px!default;
$kilo-size:         48px!default;

$h1-size:           33px!default; // .alpha
$h2-size:           25px!default; // .beta
$h3-size:           23px!default; // .gamma
$h4-size:           22px!default; // .delta
$h5-size:           16px!default; // .epsilon
$h6-size:           15px!default; // .zeta

$milli-size:        12px!default;
$micro-size:        8px!default;



/*------------------------------------*\
    $BRAND
\*------------------------------------*/
/**
 * Brand stuff
 */
$brand-color:       #00ce5e!default;
$brand-face:        "Helvetica Neue", sans-serif!default;

/**
 * How big would you like round corners to be by default?
 */
$brand-round:        4px!default;





/*------------------------------------*\
    $OBJECTS AND ABSTRACTIONS
\*------------------------------------*/
/**
 * Which objects and abstractions would you like to use?
 */
$use-grids:         true;
$use-flexbox:       false!default;
$use-columns:       false!default;
$use-nav:           true;
$use-options:       false!default;
$use-pagination:    false!default;
$use-breadcrumb:    false!default;
$use-media:         false!default;
$use-marginalia:    false!default;
$use-island:        false!default;
$use-block-list:    false!default;
$use-matrix:        false!default;
$use-split:         false!default;
$use-this-or-this:  false!default;
$use-link-complex:  false!default;
$use-flyout:        false!default;
$use-arrows:        false!default;
$use-sprite:        false!default;
$use-icon-text:     false!default;
$use-beautons:      false!default;
$use-lozenges:      false!default;
$use-rules:         false!default;
$use-stats:         false!default;
$use-greybox:       false!default;





/*------------------------------------*\
    $FRAMEWORK
\*------------------------------------*/
/**
 * inuit.css will work these next ones out for use within the framework.
 *
 * Assign our `$base-line-height` to a new spacing var for more transparency.
 */
$base-spacing-unit: 15px;
$half-spacing-unit: $base-spacing-unit / 2;
$line-height-ratio: $base-line-height / $base-font-size;


/*------------------------------------*\
    LOCAL VARS
\*------------------------------------*/


/*************************
* COLORS
**************************/

$color-ci: #44c8f5!default;
$color-ci-2nd: #96c93f!default;
$color-black: #000!default;
$color-white: #fff!default;
$color-txt: $color-black;
$color-highlight: #791667!default;
$color-grey-light: #e4e6e7!default;

$colors: (
	c--ci $color-ci,
	c--ci-2nd $color-ci-2nd,
	c--black $color-black,
	c--white $color-white,
	c--txt $color-txt,
	c--highlight $color-highlight,
	c--grey-light $color-grey-light
);


@each $color in $colors {
    .#{nth($color, 1)} {
      color: nth($color, 2);
    }
	.bg-#{nth($color, 1)} {
      background-color: nth($color, 2);
    }
}






%svg-common {
	background: url(svg/sprite.css-111f71bc.svg) no-repeat;
}

.svg-arrow-down {
	@extend %svg-common;
	background-position: 88.46175793658342% 74.86789299114643%;
	width: 9.843px;
	height: 8.524px;
}

.svg-icon-abschluss {
	@extend %svg-common;
	background-position: 99.91612090680101% 45.026178010471206%;
	width: 86px;
	height: 86px;
}

.svg-icon-arrow--ci-second {
	@extend %svg-common;
	background-position: 88.90446013819074% 78.85355555555556%;
	width: 11.333px;
	height: 18px;
}

.svg-icon-arrow--negative {
	@extend %svg-common;
	background-position: 86.50170565250485% 78.85355555555556%;
	width: 11.333px;
	height: 18px;
}

.svg-icon-arrow--positive {
	@extend %svg-common;
	background-position: 84.09895116681898% 78.85355555555556%;
	width: 11.333px;
	height: 18px;
}

.svg-icon-ausstellung {
	@extend %svg-common;
	background-position: 43.32493702770781% 54.79947643979058%;
	width: 86px;
	height: 86px;
}

.svg-icon-banderole {
	@extend %svg-common;
	background-position: 50.33631123919309% 0;
	width: 136px;
	height: 129px;
}

.svg-icon-bildung {
	@extend %svg-common;
	background-position: 78.25365239294712% 22.513089005235603%;
	width: 86px;
	height: 86px;
}

.svg-icon-download {
	@extend %svg-common;
	background-position: 86.02549533294008% 75.24734282820638%;
	width: 21.896px;
	height: 10.841px;
}

.svg-icon-energie {
	@extend %svg-common;
	background-position: 0 77.31256544502618%;
	width: 86px;
	height: 86px;
}

.svg-icon-forschung {
	@extend %svg-common;
	background-position: 21.662468513853906% 77.31256544502618%;
	width: 86px;
	height: 86px;
}

.svg-icon-horizont {
	@extend %svg-common;
	background-position: 21.662468513853906% 99.82565445026178%;
	width: 86px;
	height: 86px;
}

.svg-icon-informatik {
	@extend %svg-common;
	background-position: 64.98740554156171% 77.31256544502618%;
	width: 86px;
	height: 86px;
}

.svg-icon-kommunikation {
	@extend %svg-common;
	background-position: 99.91612090680101% 0;
	width: 86px;
	height: 86px;
}

.svg-icon-kreuz {
	@extend %svg-common;
	background-position: 74.72708803611738% 89.09672897196262%;
	width: 40px;
	height: 40px;
}

.svg-icon-logo-erfinderland {
	@extend %svg-common;
	background-position: 0 0;
	width: 174.667px;
	height: 209.334px;
}

.svg-icon-lupe {
	@extend %svg-common;
	background-position: 79.44572748267899% 70.65406698564594%;
	width: 50px;
	height: 50px;
}

.svg-icon-marker-gruen {
	@extend %svg-common;
	background-position: 40.56996077450886% 97.85924716944334%;
	width: 59.041px;
	height: 78.324px;
}

.svg-icon-marker-violet {
	@extend %svg-common;
	background-position: 41.19903103837871% 33.10442521479383%;
	width: 59.041px;
	height: 78.324px;
}

.svg-icon-marker-violet--left {
	@extend %svg-common;
	background-position: 55.12514181795881% 33.10442521479383%;
	width: 59.041px;
	height: 78.324px;
}

.svg-icon-material {
	@extend %svg-common;
	background-position: 0 99.82565445026178%;
	width: 86px;
	height: 86px;
}

.svg-icon-medizin {
	@extend %svg-common;
	background-position: 78.25365239294712% 0;
	width: 86px;
	height: 86px;
}

.svg-icon-mobilitaet {
	@extend %svg-common;
	background-position: 99.91612090680101% 67.53926701570681%;
	width: 86px;
	height: 86px;
}

.svg-icon-nobel {
	@extend %svg-common;
	background-position: 99.91612090680101% 22.513089005235603%;
	width: 86px;
	height: 86px;
}

.svg-icon-optik {
	@extend %svg-common;
	background-position: 78.25365239294712% 45.026178010471206%;
	width: 86px;
	height: 86px;
}

.svg-icon-praxis {
	@extend %svg-common;
	background-position: 21.662468513853906% 54.79947643979058%;
	width: 86px;
	height: 86px;
}

.svg-icon-sprache-de {
	@extend %svg-common;
	background-position: 53.3581986143187% 89.09672897196262%;
	width: 50px;
	height: 40px;
}

.svg-icon-sprache-en {
	@extend %svg-common;
	background-position: 64.9055427251732% 89.09672897196262%;
	width: 50px;
	height: 40px;
}

.svg-icon-sprachen {
	@extend %svg-common;
	background-position: 0 54.79947643979058%;
	width: 86px;
	height: 86px;
}

.svg-icon-studieren {
	@extend %svg-common;
	background-position: 43.32493702770781% 77.31256544502618%;
	width: 86px;
	height: 86px;
}

.svg-icon-violet-mask {
	@extend %svg-common;
	background-position: 56.73708956323437% 53.42483583399902%;
	width: 28.271px;
	height: 76.171px;
}


.font--base {
	font-family: $base-font-family;
	font-style: normal;
	font-size: $base-font-size;
	line-height: $base-line-height;
}

.font--base--important {
	font-family: $base-font-family!important;
}

.font--bold {
	font-weight: bold;
}

.font--normal {
	font-weight: normal!important;
}

.font--italic {
	font-style: italic;
}

/*
.font--brand {
	font-family: 'actopolisregular'!important;
}
*/

/* H's */

h1, h2, h3, h4, h5, h6 {
}

h2, .beta {
	line-height: 1;
}

.font--positive {
	color: $color-black;
}

.font--negative {
	color: $color-white;
}

.uppercase {
	text-transform: uppercase;
}

.text-align--center {
	text-align: center;
}

.text-align--right {
	text-align: right;
}

.display--inline-block {
	display: inline-block;
}

.hide--palm {
	@include media-query(palm) {
		display: none!important;
	}
}

.hide--desk {
	@include media-query(desk) {
		display: none!important;
	}
}

.hide--lap {
	@include media-query(lap) {
		display: none!important;
	}
}

.sprite--inline-block, .svg-icon {
	vertical-align: middle;
	display: inline-block;
}

.icon--rounded {
	border-radius: 50%;
	border: 2px solid $color-ci;
	background-color: $color-white!important;
}

.svg-icon-download {
	margin-top: 4px;
	font-size: 0!important;
}

.bg-wires {
	position: absolute;
	width: 100%;
}

.bg-wires--palm-port {

	@media screen and (max-width: $lap-start - 1) and (orientation: landscape) {

		display: none!important;

	}
}

.bg-wires--palm-land {

	@media screen and (max-width: $lap-start - 1) and (orientation: landscape) {

		display: block!important;

	}

}

.rotated--90 {
	transform: rotate(90deg);
}

.rotated--180 {
	transform: rotate(180deg);
}

.rotated--270 {
	transform: rotate(270deg);
}

#wrapTyp4 {
	min-width: inherit;
	overflow: visible;
  margin-bottom: 30px;
	@include media-query(palm) {
		border-width: 5px;
		top: 0;
	}
}

body {
	@extend .font--base;
}


#content {

	> .artikeluschrift, > .artikeltext {
		@include media-query(palm) {
			padding-left: $base-spacing-unit;
			padding-right: $base-spacing-unit;
		}
	}

}


.bu {
    display: block;
    font-size: 9px;
    line-height: 12px;
    margin-top: 5px;
}

h3, .gamma {
    line-height: 1.1em !important;
}

.element-description {
	margin-top: $base-spacing-unit*2;
	border-top: 1px solid #000;
	padding-top: $base-spacing-unit*2;
	padding-bottom: $base-spacing-unit*2;

}

.artikeltext {
	overflow: visible;
	background-color: #fff;
	min-width: inherit;
}

.navi-footer {
	  position: absolute;
	  bottom: -1px;
	  z-index: 10000;
	  height: 26px;
	  background-color: #fff;
	  left: 50px;
	  right: 50px;
	  border: 2px solid #00caf2;
	  border-radius: 7px;
	  color: #000;
	  line-height: 23px!important;
	  font-size: 12px!important;
}

.navi-footer a {
   color: #000;
}

div.gi-lightbox {
    background-color: white;
    border: 5px none #fff;
    border-radius: 34px;}

#fancybox-outer {background-color: transparent !important;}

.pg-module-box {
	position: relative;
	margin-bottom: $base-spacing-unit;

	&.pg-module-box--icon-offset {
		margin-top: 40px;
		margin-bottom: 0;
	}

	.icon--rounded {
		position: absolute;
		top: -30px;
		right: $base-spacing-unit;

		@include media-query(palm) {
			transform-origin: right;
			transform: scale(0.8);
		}
	}
}

.pg-module-box__head {
	overflow: visible;

	.grid {
		margin-left: -30px;
		> * {
			padding-left: 30px;
		}		
	}

   .pg-module-box--icon-offset > & {
   		margin-top: -40px;

		 .pg-module-box__head__txt {
			padding-top: 40px;

			@include media-query(palm) {
				margin-bottom: $base-spacing-unit;
			}
			
			> * {
				padding-right: 85px;

			}

			> h1, > h2, > h3, > h4 {

				@include media-query(palm) {
					font-size: 1.3em;
				}
			}

			
		}   		

	}

	.pg-module-box--fixed-height > & {
		height: 200px;
	}
	

/*
	> h2 {
		color: $color-ci-2nd;
		margin-top: 1em;
		padding-top: $base-spacing-unit;
	}
*/
}

.pg-module-box__inner {
	transition: height 0.3s;
	height: 0;
	overflow: hidden;

	.pg-module-box--open & {
		height: inherit;
	}
}

.pg-module-box__content {
	padding-bottom: $base-spacing-unit;
}

.pg-module-box__content__headline {
	border-bottom: 2px solid $color-ci;
	margin-bottom: $half-spacing-unit;

	> * {
		margin-bottom: 0;
	}
}

.pg-module-box__footer {
	margin-top: $base-spacing-unit;
	border-bottom: 2px solid $color-ci;

	&.align--right {
		text-align: right;
	}
}

.pg-module-box--open {

	.pg-module-box__footer__btn {
		
		@extend .open;

	}

}

.pg-module-box__footer__btn {
	cursor: pointer;
	display: inline-block;
	width: 70px;
	height: 20px;
	background: $color-ci;
	margin-bottom: -12px;
	text-align: center;
	overflow: hidden;

	&.open {
		> * {
			@extend	.rotated--270;
		}
	}

	&.closed {
		> * {
			@extend	.rotated--90;
		}
	}

	* {
		&:hover {
			color: $color-white;
			text-decoration: underline;
		}

		font-size: $h5-size;
		color: $color-white;
	}
}
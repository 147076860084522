
.pg-module, .pg-module--unpadded {
	padding: $base-spacing-unit;
	line-height: $base-line-height;

}

.pg-module--unpadded {
	@include media-query(desk) {
		padding-left: 0;
		padding-right: 0;
	}
}

.pg-img {
	margin-bottom: $base-spacing-unit;
	padding-top: 3px;
	position: relative;
	overflow: hidden;
	
	> img {
		width: 100%;
		height: auto;
		margin-bottom: $half-spacing-unit;
	}



	> .pg-img__desc {
		color: $color-ci;
	}

}

.svg-icon-lupe {
	  margin-top: -61px;
	  position: relative;
	  float: right;
}

.pg-module-map {
	position: relative;

	> img {
		width: 100%;
		height: auto;
	}
}

.pg-video {
	> * {
		width: 100%;
	}
}

.pg-module-map__flag__active {
	display: none;
	position: absolute;
	bottom: 1px;
	left: 5px;

	> .svg-icon {
		  position: absolute;
		  bottom: 0;
	}


}

.pg-module-map__flag__marker  {
	position: absolute;
	bottom: 0;
}

.pg-module-map__flag {
	position: absolute;
	cursor: pointer;
	width: 1px;
	height: 1px;

	&:hover {
		z-index: 966;

		> .pg-module-map__flag__active{
			display: block
		}

		> .pg-module-map__flag__marker  {
			display: none;
		}

	}
}

.pg-module-map__flag__info {
  color: #fff;
  padding-right: 5px;
  line-height: 1;
  height: auto;
  position: absolute;
  top: -77px;
  left: 37px;
  overflow: hidden;
  padding-left: 22px;
	
   &:before {
		@extend .svg-icon-violet-mask;
		  content: "";
		  display: block;
		  position: absolute;
		  left: -7px;
	}	

  > div {
	    padding-top: 7px;
	    padding-bottom: 7px;
	    padding-right: 7px;
		margin-left: -1px;
	  	background: #6F2160;
  }

}

.fancy-bg {
	display: none;
}

.pg-lightbox-content {
	padding: $base-spacing-unit*2.5 $base-spacing-unit $base-spacing-unit $base-spacing-unit;
	max-width: 510px;

	&.image-only {
		max-width: 800px;
	}

	@include media-query(palm) {
		padding: $half-spacing-unit;
	}
		
	
	img {
		width: 100%;
		height: auto;

		@include media-query(palm) {
			margin-top: $base-spacing-unit;
		}
		
	}
}
